@media screen(md) {
  /* Applies only on desktop device, where we could inject ads in columns */

  /* white-label base */

  .col-span-12 .ad-row-column {
    order: 2;
  }

  article .col-span-12 .ad-row-column {
    order: initial;
  }

  .md\:col-span-12 .md\:col-span-3:nth-child(1) .md\:col-span-12 .ad-row-column {
    margin-left: 150%;
  }

  .md\:col-span-12 .md\:col-span-3:nth-child(2) .md\:col-span-12 .ad-row-column {
    margin-left: -150%;
  }
}
/* Hide read more when there is an ad */
.jw-flag-ads ~ div a {
  display: none;
}
